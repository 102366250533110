<template>
  <div
    class="gradient-mask-checkerboard bg-summer-sky h-100"
    :style="slidePosition"
  >
    <div class="gradient-checkerboard checkerboard h-100 w-100"></div>
  </div>
</template>

<script>
import { slidePositionParallax } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      let response = slidePositionParallax(this.globalUnits, this.slideIndex)
      response.width = "600%"
      return response
    }
  }
}
</script>

<style>
.checkerboard {
  color: white;
  background-color: #2babe2;

  background-image: repeating-linear-gradient(
      transparent,
      transparent 322px,
      rgba(255, 255, 255, 0.5) 322px,
      rgba(255, 255, 255, 0.5) 324px
    ),
    repeating-linear-gradient(
      270deg,
      transparent,
      transparent 322px,
      rgba(255, 255, 255, 0.5) 322px,
      rgba(255, 255, 255, 0.5) 324px
    );
}
.gradient-mask-checkerboard {
  mask-image: linear-gradient(-90deg, black 0%, black 80%, transparent 100%);
}
.gradient-checkerboard {
  mask-image: linear-gradient(
    -90deg,
    transparent 10%,
    black 40%,
    black 80%,
    transparent 100%
  );
}
</style>
